import { decisionsTree } from './decision.reducer'
import { game } from './game.reducer'
import { handMatrix } from './hand-matrix.reducer'
import { rangeExplorer } from './range-explorer.reducer'
import { preflopGame } from './preflop-game.reducer'
import { userData } from './user.reducer'
import { subscriptions } from './subscription.reducer'
import { roles } from './role.reducer'
import { cardsConverting } from './cards-converting.reducer'
import { odinLoading } from './odin-loading.reducer'
import { bot } from './bot.reducer'
import { historyGamePlay } from './history-game.reducer'
import { battleSession } from './my-sesions.reducer'
import { setting } from './setting.reducer'
import { affiliateDiscountCodes } from './affiliate-discount-code.reducer'

export default {
  decisionsTree,
  game,
  handMatrix,
  rangeExplorer,
  preflopGame,
  userData,
  subscriptions,
  roles,
  cardsConverting,
  odinLoading,
  bot,
  historyGamePlay,
  battleSession,
  setting,
  affiliateDiscountCodes
}
