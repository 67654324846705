export const en = {
  submit: "Submit",
  password: "Password",
  password_placeholder: "Your password",
  email: "Email address",
  email_placeholder: "Your email address",
  signin: "Sign In",
  log_in: "Log In",
  logout: "Log Out",
  signup: "Sign Up",
  username: "Username",
  username_placeholder: "Your username",
  first_name: "First name",
  first_name_placeholder: 'Your first name',
  last_name: "Last name",
  last_name_placeholder: 'Your last name',
  birthday: 'Birthday',
  confirm_password: 'Confirm password',
  confirm_password_placeholder: 'Confirm password',
  birthday_placeholder: "Birthday",
  country_of_residence: "Country of Residence",
  password_strength: "Password strength",
  password_strength_message: "Password must contain at least 8 characters, one uppercase letter and one number",
  already_have_an_account: "Already have an account?",
  not_a_member_yet: "Not a member yet?",
  name: "Name",
  name_placeholder: "Your name",
  message: "Message",
  message_placeholder: "Your message",
  edit: "Edit",
  delete: "Delete",
  yes: "Yes",
  no: "No",
  register: "Register",
  sign_out: "Sign Out",
  cancel: "Cancel",
  ok: "Ok",
  valid_email: "Must be a valid email",
  password_not_match: "Passwords don't match",
  password_match: "It must not be current password.",
  incorrect: "Incorrect password.",
  altHistory: "View Alt-Sub History",
  grandAlt: "Grant Alt Subscription",
  viewAlt: "View Alt Subscription",
  expireDate: "Expire Date",
  subType: "Subscription Type",
  createdDate: "Created Date",
  confirmDeactiveSub: "Are you sure you want to deactive alt subscription",
  benchmarks: "Benchmarks",
  saveSpot: "Save Spot",
  filter: "Filter",
  infiniteLoading: "Yay! You have seen it all",
  loading: "Loading...",
  loginAccess: "Please Login to Get Access",
  benchmarkTitle: "Benchmarks",
  termCondition: "Terms & Conditions",
  privatePolicy: "Privacy Policy",
  contactUs: "Contact us",
  companyName: "Odin Technology Solutions LLC",
  companyAddress: "Hunkins Waterfront Plaza, Suite 556, Main Street, Charlestown, Nevis",
  tutorials: "Tutorials",
  aggReports: "Agg Reports",
  buyNow: "Buy Now",
  firstText: "The % of the total range that chooses this bet size | ",
  secondText: "The number of individual combinations that chooses this bet size.",
  preview: "Preview",
  firstTitleOption: "BTN, CO, HJ...",
  secondTitleOption: "B, B1, B2...",
  colorOptions: "Colour Options",
  root: "ROOT",
  reloadSimulator: "Reload simulation",
  renewTitle: "Do you want to renew the subscription automatically?",
  cancelContent: "Your subscription plan will be valid until {date}. Are you sure to cancel the plan?",

  "validations.password.sign_up_rules": "Must contain 8 characters, one uppercase letter and one number",
  "validations.password_confirmation.required": "Password confirm is required",
  "validations.first_name.required": 'Your first name is required for registation.',
  "validations.last_name.required": 'Your last name is required for registation.',

  street_select_label: "Street",
  game_type_select_label: "Select Game Type",
  players_select_label: "Number of Players",
  stack_select_label: "Select Stack (BB)",
  type_select_label: "Select Type",
  positions_select_label: "Select Positions",
  select_flop_card: "Select Flop Cards",
  site_select_label: "Site",
  stake_select_label: "Stake",
  open_size_select_label: "Open Size",
  next: 'Next',
  select_more_positions: 'Select {number} more position(s)',
  select_more_cards: 'Select {number} more card(s)',
  "blind_strategy_select_label": "Blind vs Blind strategy",

  "forgot_password": "Forgot your password?",
  "forgot.notify_email_reset": "Please check your email to reset your password.",
  "forgot.notify_error": "There is no account associated with the inserted email. Please try again.",
  "forgot.link_expired": "This link is expired, please try to reset again.",

  "configSection.firstToolTip": "Displays your range relative to how often it arrives at this node with those combos.",
  "configSection.secondTooltip": "For eg. if you begin the hand with all 4 combos of T9s and only get to the turn with 1 combo of T9s, the T9s square will be filled 25% of the way.",
  "configSection.tooltipCompareEv": "This will visually display the EV gained by one bet size over another.",
  "configSection.tooltipRunout": "Displays the strategy and EV/EQ across every possible turn/river card",
  "largeEv": " = Large EV loss",
  "slightEv": " = Slight EV loss",
  "evNeutral": " = EV neutral",
  "slightEvGain": " = Slight EV gain",
  "largeEvGain": " = Large EV gain",

  "main_page.select_turn_card.title": "Select turn card",
  "main_page.select_river_card.title": "Select river card",
  "pot": "POT:",
  "total_pot": "TOTAL POT:",
  "ip": "IP",
  "oop": "OOP",
  "strategy": "Strategy",
  "equity": "Equity",
  "new_sim": "New Sim",
  "playTheBot": "Battle",
  "ev": "EV",
  "eq": "EQ",
  "range-explorer": "Range explorer",
  "range": "Range",
  "compare-ev": "Compare EV",
  "strategy-ev": "Strategy + EV",
  "runouts-explorer": "Runouts Explorer",
  "simplifier": "Simplifier",
  "options": "Options",
  "notes": "Notes",
  "fold": "Fold",
  "check_and_call": "Check & Call",
  "bet_and_raise": "Bet & Raise",
  "strat": "Strat",
  "stratEV": "Strat + EV",

  "reloadPage.firstText": "You have either opened a new sim or there was an error and you'll have to reload this sim.",
  "reloadPage.secondText": "Note: only 1 sim may be browsed at a time.",
  "reloadPage.thirdText": "We've found an issue with this game. Please click OK to go back to Trainer Selection Page.",

  "rightSection.firstText": "Select to view the strategy for the specific suit combinations",
  "rightSection.secondText": "Eg. if you choose the Spade/Spade option, the matrix will display the strategy for all combos suited in spades.",
  "notifyModalPTB": "You've got timed out after 15 minutes. Please Reload Simulation to continue.",
  "notifyModalNotPTB" : "You've got timed out after 15 minutes.",
  "notifyModalContent": "Please revisit Strategy Selection page or My Sims to re-open the simulation.",
  "notifyModal.returnHome": "Return To Homepage",

  /* Type options label text */
  "type_options.srp": "SRP",
  "type_options.3bp": "3BP",
  "type_options.4bp": "4BP",
  "type_options.l3bet": "L/3B",
  "type_options.l4bet": "L/4B",
  "type_options.limped": "LIMP",
  "type_options.3bet": "3B",
  "type_options.4bet": "4B",
  "type_options.iso": "ISO",
  "type_options.random": "Random",
  "no_options": "Spot Not Available",

  "missing_input": "Missing {input_name}",
  "input_name.street": "Street",
  "input_name.game_type": "Game Type",
  "input_name.stack": "Stack",
  "input_name.sim_type": "Type",
  "input_name.site": "Site",
  "input_name.stake": "Stake",
  "input_name.open_size": "Open Size",

  "simplifier.firstToolTip": "This function hides excessive sizes that don’t occur more then the frequency set.",
  "simplifier.secondTooltip": "Example: if the strategy bets all in 0.03% and the threshold is set to 0.05% the bet would be hidden making the solution less cluttered",

  "header.text": "Click through nodes to navigate full tree",

  "range_explorer.oop": "OOP",
  "range_explorer.ip": "IP",
  "range_explorer.show_on_grids": "Show on grids:",
  "range_explorer.options": "Options:",
  "range_explorer.combos_number": "{position} {number} Combos",
  "range_explorer.square_size": "Square size proportional to height",

  "new_sim.replace_current": "Replace current sim",
  "new_sim.new_tab": "Open in new tab",

  "advanFilterFrom": "From: {date}",
  "advanFilterTo": "To: {date}",
  "between": "Between",
  "lessThan": "Less than:",
  "moreThan": "More than:",

  "hand_type.royal_flush": "Royal Flush",
  "hand_type.straight_flush": "Straight Flush",
  "hand_type.quads": "Quads",
  "hand_type.fullhouse": "Full House",
  "hand_type.flush": "Flush",
  "hand_type.trips": "Trip",
  "hand_type.set": "Set",
  "hand_type.two_pair": "Two Pair",
  "hand_type.overpair": "Overpair",
  "hand_type.top_pair": "Top Pair",
  "hand_type.underpair": "Under Pair",
  "hand_type.second_pair": "Second Pair",
  "hand_type.third_pair": "Third Pair",
  "hand_type.ace_high": "Ace High",
  "hand_type.king_high": "King High",
  "hand_type.nothing": "Nothing",
  "hand_type.combo_draw": "Combo Draw",
  "hand_type.flush_draw": "2Tone",
  "hand_type.eight_out_straight_draw": "8 Out Straight Draw",
  "hand_type.four_out_straight_draw": "4 Out Straight Draw",
  "hand_type.no_draw": "No Draw",
  "hand_type.low_pair": "Low Pair",
  "hand_type.straight": "Straight",
  "page_title.preflop": "Preflop",
  "contact_us": "Contact Us",
  "contact.for_billing": "For billing issues",
  "contact.for_any": "For anything else",
  "contact.discord_message": "Contact us on Discord at",
  "click_here": "Click here",
  "contact.discord_link_text": "https://discord.io/OdinPoker",
  "contact.discord_link": "https://discord.com/widget?id=826980130509291561&theme=dark",
  "contact.send_us_message": "Send us a message",
  "contact.or_send_us": " Or, send us an email as",
  "contact.send_us_link": "hello@easysim.com",

  "free_preflop_sim": "Free Preflop Sims",
  "free_postflop_sims": "Free Postflop Sims",
  "mysim": "My Sims",
  "mysim.board": "Board",
  "mysim.positions": "Positions",
  "mysim.stack_size": "Stack Size",
  "mysim.pot_type": "Pot Type",
  "mysim.notes": "Notes",
  "mysim.players": "Players",
  "mysim.my_notes": "My Notes",
  "mysim.last_date_view": "Date Viewed",
  "mysim.game_type": "Game Type",
  "mysim.open_sim": "Open SIM",
  "mysim.line": "Line",
  "mysim.typing_note": "Start typing your notes",
  "mysim_choose_card": "Choose card you want to note",
  "mysim.delete_note": "Are you sure you want to permanently delete {target}?",
  "tree_building.header": "Tree Building Parameters",
  "tree_building.click_ranges_icon": "Click range icon to view starting ranges",
  "tree_building.copy_clipboard": "Copy parameters to clipboard",
  "tree_building.starting_pot": "Starting pot",
  "tree_building.effect_stack": "Effective stack",
  "tree_building.min_bet_size": "Min bet size",
  "tree_building.max_spread": "Max spread",
  "tree_building.bet_size": "Bet sizes",
  "tree_building.raise_size": "Raise sizes",
  "tree_building.donk_size": "Donk sizes",
  "tree_building.add_allin": "Add allin",
  "tree_building.dont_bet": "Don't 3 bet",
  "landing_page.subscribe": "Subscribe",
  "landing_page.largest_database": "Largest Database",
  "landing_page.comming_soon": "COMING SOON",
  "landing_page.land.high_quality": "Highest Quality Sims",
  "landing_page.easy_use": "Easy to Use",
  "landing_page.analysis_tools": "Analysis Tools",
  "landing_page.subscribe_now": "Subscribe Now",
  "landing_page.first": "To receive updates and be notified when we go live,",
  "landing_page.second": "please enter your email address!",
  "landing_page.title": "Never Use a Solver Again",
  "landing_page.title_try_odin": "Try Odin for free",
  "landing_page.what_is_odin": "What is Odin?",

  "required_field": "This is required",
  "display_in_bb": "Display chips in BB",
  "hide_explanation": "Hide Explanation",
  "view_tree_building": "View tree building parameters",
  "payment_detail": "My payment details",
  "contact_us_billing": "Contact us for billing enquiries",
  "change_password": "Change Password",
  "save_change": "Save changes",
  "save": "Save",
  "my_account": "My Account",
  "select_card": "Select Card",
  "title_details": "My Details",
  "title_subscriptions": "My Subscriptions",
  "title_payments": "My Payments",

  "supportTitle": "You are able to get instant refund in 24hrs after purchase. Please contact {email} for this refund and any other refund/cancellation concerns.",

  "my_account.success_change": "You details have been successfully changed",
  "my_account.fail_change": "You details changed fails",
  "report_bug": "Report Bug",
  "contact.report_issue": "Report an issue",
  "bug_type": "Type",
  "subject": "Subject",
  "description": "Description",
  "bug_type_placeholder": "Type",
  "subject_placeholder": "Subject",
  "description_placeholder": "Description",
  "compare_ev_select_two_action": "Select exactly two actions for EV comparison ",
  "footer.copyright_text": "Odinpoker {year}",
  "odin_support_email": "support@odinpoker.io",
  "subscription-page.huge_database": "Huge database",
  "mainpage.notifyTitle": "Action not available",

  "tooltip_mainpage.equity": "Your share of the pot if both players checked down to the river from this point",
  "tooltip_mainpage.ev": "What you expect to win or lose on average in the long run in a given situation",
  "tooltip_range_explorer.ev_rescale": "EV but rescaled in a way that 100 = the pot size to make it more compareable with equity",
  "tooltip_range_explorer.ev_per_eq": `Ev / Equity function is used to see equity realization for each hand combo within the given range and is calculated by “(EV rescaled/ equity) * 100”.can
    Useful to see which hand combos within a range  gain/lose equity due to the overall strength or weakness of the range as a whole.`,
  "runouts_explorer.close_loader_message": "To exit the runouts explorer before loading completes, please exit the browser window.",
  "runouts_explorer.loader_message": "This may take up to a few minutes to load in certain spots",
  "confirm_diaglog.yes": "YES",
  "confirm_diaglog.no": "NO",

  "weighted_strategy": "Weighted Strategy",
  "ip_title": "Your IP Adress: {ip}",

  "countdown.title": "When 20 seconds pass you can view postflop.",
  "countdown.title_button": "VIEW POSTFLOP",

  "free_trial.subscribe": "Subscribe",
  "free_trial.view_free_sims": "View My free Sims",
  "free_trial.mysim": "My Free Sims",
  "free_trial.subscribe_popup.unlock_preflop": "To unlock preflop",
  "free_trial.subscribe_popup.subscribe_now": "Subscribe Now",
  "free_trial.subscribe_popup.unclock_main_page": "To unlock turns and rivers",
  "notify_modal.title": "Action not available",
  "notify_modal.can_not_open_more_than_1_sim_message": "You may only have 1 simulation open at the same time. If you wish to view a new spot please use Replace Current option under New Sim or just exit the browser window and begin from the start",
  "notify_modal.not_accept": "You can only access the Main Page through the Preflop page using the Next button.",
  "admin_portal": "Admin Portal",

  "action_option.title": "while you wait, you can explore preflop",
  "user_profiles.title": "User Profiles",
  "user_requests.title": "User Requests",
  "user_profiles.id": "ID",
  "user_profiles.user_email": "User Email",
  "user_profiles.role": "Role",
  "user_profiles.affiliates": "Affiliates",
  "user_profiles.date_created": "Date Created",
  "user_profiles.full_access": "Access",
  "user_profiles.action": "Action",
  "user_profiles.search_user": "Search for User",
  "user_profiles.resent": "Resent Email",
  "user_profiles.activity_log": "Activity log",
  "user_profiles.free_user": "Free User",
  "modal_confirm.title": "Are you sure you want to delete this user permanently?",

  "activity.date": "Date",
  "activity.numberLog": "Number of logins",
  "activity.numberOpened": "Number of sims opened",
  "activity.timeSpent": "Time spent logged in",

  "details_page.title_user": "Edit User Details",
  "details_page.success": "User details change successfully!",
  "details_page.fail": "Fail to change user details. Please try again!",
  "details_page.current_password": "Current Password",
  "details_page.current_password_holder": "Your current password",
  "details_page.update_password": "Update Password",
  "details_page.new_password": "New Password",
  "details_page.new_password_holder": "Your new password",
  "details_page.confirm_password": "Confirm Password",
  "details_page.confirm_password_holder": "Confirm your new password",

  "subscription.hide_failed": "Hide failed",
  "subscription.no": "No",
  "subscription.sub_id": "Subscription ID",
  "subscription.sub": "Subscription",
  "subscription.amount": "Amount",
  "subscription.date_create": "Date Created",
  "subscription.status": "Status",
  "subscription.next_payment": "Renewal Date",
  "subscription.full_access": "Access",
  "subscription.ip": "IP history",
  "subscription.table": "Subscriptions Table",
  "subscription.plan": "Subscriptions Plan",
  "subscription.billing_cycle": "Billing Cycle",
  "subscription.payment": "Payment",
  "subscription.subscribed_date": "Subscribed Date",
  "subscription.renewal_date": "Renewal Date",
  "subscription.auto_renewal": "Auto Renewal",
  "subscription.cancel_subscription": "Cancel My Subscription",
  "subscription.change_subscription_plan": "Change Subscription Plan",
  "subscription.change_subscription": "Changes Subscription",
  "subscription.confirm_cancel_subscription": "Yes, cancel my subscription.",
  "subscription.refuse_cancel_subscription": "No, keep my subscription.",
  "subscription.full_access_filter": "Full Access",
  "subscription.changeSubscription": "Change Subscription",
  "subscription.confirm": "Confirm Popup",

  "cancelSubscription.title": "Are you sure you want to cancel your subscription?",
  "cancelSubscription.content": "If you used a discount code and the promotion has finished it will no longer apply if you choose to re-subscribe at a later date.",

  "contentModalNotice": "Loading saved spot, this may take up to 30s",

  "playTheBot.title": "Battle vs Odin (beta)",
  "playTheBot.gameType": "Game Type",
  "playTheBot.street": "Street",
  "playTheBot.stackSize": "Stack Size",
  "playTheBot.heroSeat": "Hero Seat",
  "playTheBot.simType": "Sim Type",
  "playTheBot.board": "Board",
  "playTheBot.suits": "Suits",
  "playTheBot.paired": "Paired",
  "playTheBot.cards": "Cards",
  "playTheBot.connectedness": "Connectedness",
  "playTheBot.confirm": "Confirm",
  "playTheBot.letsGo": "Let's Go",
  "playTheBot.perfect": "Perfect!",
  "playTheBot.good": "Good!",
  "playTheBot.better": "Could Be Better!",
  "playTheBot.mistake": "Mistake!",
  "playTheBot.punt": "Punt!",
  "playTheBot.next": "Next",
  "playTheBot.skip": "Skip hand history replay",
  "playTheBot.result": "Results",
  "playTheBot.score": "Session score",
  "playTheBot.nodeScore": "Node Scores",
  "playTheBot.handScore": "Hand Score",
  "playTheBot.goToSim": "Go to sim",
  "playTheBot.nextHand": "Next Hand",
  "playTheBot.auto": "Auto play next hand",
  "playTheBot.instant": "Instant Play",
  "playTheBot.skipPlay": "Skip play preflop",
  "playTheBot.autoNextDecision": "Auto Play Next Decision",

  "endSessionTitle": "End Session",
  "endSessionContent": "Are you sure you want to end this session and view your results?",

  "button.ok": "OK",
  "signup.successfully": "Successfully",
  "signup.email_verification": "Email Verification",
  "toast.account_activated_successfully": "Your account has been activated. Now you can sign in.",
  "toast.account_activated_before": "Your account has already been activated before, please try signing in.",
  "toast.email_confirmation_link_expired": "This link is expired, you can get support from us through Contact Us!",
  "toast.email_confirmation_failed": "Something went wrong, you can get support from us through Contact Us",
  "toast.accepted_auto_renew_success": "Your subscription will auto renew. You will receive an email when it begin renew process!",
  "toast.accepted_auto_renew_failed": "Subscription renew has expired, please subscribe manually",
  "toast.accepted_auto_renew_something_wrong": "Some data is wrong, cannot accept your auto renew subscription now!",


  "toast.update_info_success": "Your account info has been updated successfully!",
  "toast.update_info_failed": "Your account info update failed",
  "tree-building": "Tree Parameter",
  "round_strategies_to_closest": "Round strategies to closest",
  "round_strategies_tooltip_message": "This function round strategy to nearest value",
  "hide_strategies_less_than": "Hide strategies less than",

  "subscription.search_for_subscription": "Search for Subscription",
  "subscription.confirm_popup_title": "Confirm Full Access",
  "subscription.remove_full_access_message": "Are you sure you want to remove full access of this account ?",
  "subscription.set_full_access_message": "Are you sure you wanted to set this user as PROMOTIONAL ACCOUNT?",
  "affiliate_code.table": "Affiliate Codes Table",
  "affiliate_code.search_for_affiliate_code": "Search for Affiliate Code",
  "affiliate_code.name": "Name",
  "affiliate_code.affiliate_url": "Affiliate Url",
  "affiliate_code.affiliate_code": "Affiliate Code",
  "affiliate_code.discount_code": "Discount Code",
  "affiliate_code.discount_percent": "Discount Percent",
  "affiliate_code.create": 'Create Affiliate Code',
  "affiliate_code.popup.title.create": 'Create Affiliate Code',
  "affiliate_code.popup.title.edit": 'Edit Affiliate Code',
  "affiliate_code.save_button": 'Save',
  "affiliate_code.action": 'Action',
  "affiliate_name": "Name",
  "affiliate_url": "Affiliate URL",
  "affiliate_code": "Affiliate Code",
  "discount_code": "Discount Code",
  "discount_value": "Discount Value",
  "is_default": "Default?",
  "affiliate_name_placeholder": "Name",
  "affiliate_url_placeholder": "Affiliate URL",
  "affiliate_code_placeholder": "Affiliate Code",
  "affiliate_code.delete.ok": "OK",
  "affiliate_code.delete.cancel": "Cancel",
  "affiliate_code.delete.title": "Are you sure you want to delete this affiliate code permanently?",
  "discount_code_placeholder": "Discount Code",
  "discount_value_placeholder": "Discount Value",
  "join_our_discord": "Join our Discord!",
  "login_time": "login time",
  "preflop_sim_title": "PREFLOP",
  "update_auto_renew_failed": "Failed change auto renew info!",

  "subscribe.month": "month",
  "subscribe.monthly": "Monthly",
  "subscribe.year": "year",
  "subscribe.yearly": "Yearly (save 20%)",
  "subscribe.button": "Subscribe Now",
  "subscribe.ticket": "BEST VALUE",
  "subscribe.popup_title": "Billing Cycle",

   "countries.af": "Afghanistan",
   "countries.al": "Albania",
   "countries.dz": "Algeria",
   "countries.as": "American Samoa",
   "countries.ad": "Andorra",
   "countries.ao": "Angola",
   "countries.ai": "Anguilla",
   "countries.aq": "Antarctica",
   "countries.ag": "Antigua and Barbuda",
   "countries.ar": "Argentina",
   "countries.am": "Armenia",
   "countries.aw": "Aruba",
   "countries.au": "Australia",
   "countries.at": "Austria",
   "countries.az": "Azerbaijan",
   "countries.bs": "Bahamas",
   "countries.bh": "Bahrain",
   "countries.bd": "Bangladesh",
   "countries.bb": "Barbados",
   "countries.by": "Belarus",
   "countries.be": "Belgium",
   "countries.bz": "Belize",
   "countries.bj": "Benin",
   "countries.bm": "Bermuda",
   "countries.bt": "Bhutan",
   "countries.bo": "Bolivia, Plurinational State of",
   "countries.bq": "Bonaire, Sint Eustatius and Saba",
   "countries.ba": "Bosnia and Herzegovina",
   "countries.bw": "Botswana",
   "countries.bv": "Bouvet Island",
   "countries.br": "Brazil",
   "countries.io": "British Indian Ocean Territory",
   "countries.bn": "Brunei Darussalam",
   "countries.bg": "Bulgaria",
   "countries.bf": "Burkina Faso",
   "countries.bi": "Burundi",
   "countries.kh": "Cambodia",
   "countries.cm": "Cameroon",
   "countries.ca": "Canada",
   "countries.cv": "Cape Verde",
   "countries.ky": "Cayman Islands",
   "countries.cf": "Central African Republic",
   "countries.td": "Chad",
   "countries.cl": "Chile",
   "countries.cn": "China",
   "countries.cx": "Christmas Island",
   "countries.cc": "Cocos (Keeling) Islands",
   "countries.co": "Colombia",
   "countries.km": "Comoros",
   "countries.cg": "Congo",
   "countries.cd": "Congo, The Democratic Republic of the",
   "countries.ck": "Cook Islands",
   "countries.cr": "Costa Rica",
   "countries.hr": "Croatia",
   "countries.cu": "Cuba",
   "countries.cw": "Curaçao",
   "countries.cy": "Cyprus",
   "countries.cz": "Czech Republic",
   "countries.ci": "Côte d'Ivoire",
   "countries.dk": "Denmark",
   "countries.dj": "Djibouti",
   "countries.dm": "Dominica",
   "countries.do": "Dominican Republic",
   "countries.ec": "Ecuador",
   "countries.eg": "Egypt",
   "countries.sv": "El Salvador",
   "countries.gq": "Equatorial Guinea",
   "countries.er": "Eritrea",
   "countries.ee": "Estonia",
   "countries.et": "Ethiopia",
   "countries.fk": "Falkland Islands (Malvinas)",
   "countries.fo": "Faroe Islands",
   "countries.fj": "Fiji",
   "countries.fi": "Finland",
   "countries.fr": "France",
   "countries.gf": "French Guiana",
   "countries.pf": "French Polynesia",
   "countries.tf": "French Southern Territories",
   "countries.ga": "Gabon",
   "countries.gm": "Gambia",
   "countries.ge": "Georgia",
   "countries.de": "Germany",
   "countries.gh": "Ghana",
   "countries.gi": "Gibraltar",
   "countries.gr": "Greece",
   "countries.gl": "Greenland",
   "countries.gd": "Grenada",
   "countries.gp": "Guadeloupe",
   "countries.gu": "Guam",
   "countries.gt": "Guatemala",
   "countries.gg": "Guernsey",
   "countries.gn": "Guinea",
   "countries.gw": "Guinea-Bissau",
   "countries.gy": "Guyana",
   "countries.ht": "Haiti",
   "countries.hm": "Heard Island and McDonald Islands",
   "countries.va": "Holy See (Vatican City State)",
   "countries.hn": "Honduras",
   "countries.hk": "Hong Kong",
   "countries.hu": "Hungary",
   "countries.is": "Iceland",
   "countries.in": "India",
   "countries.id": "Indonesia",
   "countries.ir": "Iran, Islamic Republic of",
   "countries.iq": "Iraq",
   "countries.ie": "Ireland",
   "countries.im": "Isle of Man",
   "countries.il": "Israel",
   "countries.it": "Italy",
   "countries.jm": "Jamaica",
   "countries.jp": "Japan",
   "countries.je": "Jersey",
   "countries.jo": "Jordan",
   "countries.kz": "Kazakhstan",
   "countries.ke": "Kenya",
   "countries.ki": "Kiribati",
   "countries.kp": "Korea, Democratic People's Republic of",
   "countries.kr": "Korea, Republic of",
   "countries.kw": "Kuwait",
   "countries.kg": "Kyrgyzstan",
   "countries.la": "Lao People's Democratic Republic",
   "countries.lv": "Latvia",
   "countries.lb": "Lebanon",
   "countries.ls": "Lesotho",
   "countries.lr": "Liberia",
   "countries.ly": "Libya",
   "countries.li": "Liechtenstein",
   "countries.lt": "Lithuania",
   "countries.lu": "Luxembourg",
   "countries.mo": "Macao",
   "countries.mk": "Macedonia, Republic of",
   "countries.mg": "Madagascar",
   "countries.mw": "Malawi",
   "countries.my": "Malaysia",
   "countries.mv": "Maldives",
   "countries.ml": "Mali",
   "countries.mt": "Malta",
   "countries.mh": "Marshall Islands",
   "countries.mq": "Martinique",
   "countries.mr": "Mauritania",
   "countries.mu": "Mauritius",
   "countries.yt": "Mayotte",
   "countries.mx": "Mexico",
   "countries.fm": "Micronesia, Federated States of",
   "countries.md": "Moldova, Republic of",
   "countries.mc": "Monaco",
   "countries.mn": "Mongolia",
   "countries.me": "Montenegro",
   "countries.ms": "Montserrat",
   "countries.ma": "Morocco",
   "countries.mz": "Mozambique",
   "countries.mm": "Myanmar",
   "countries.na": "Namibia",
   "countries.nr": "Nauru",
   "countries.np": "Nepal",
   "countries.nl": "Netherlands",
   "countries.nc": "New Caledonia",
   "countries.nz": "New Zealand",
   "countries.ni": "Nicaragua",
   "countries.ne": "Niger",
   "countries.ng": "Nigeria",
   "countries.nu": "Niue",
   "countries.nf": "Norfolk Island",
   "countries.mp": "Northern Mariana Islands",
   "countries.no": "Norway",
   "countries.om": "Oman",
   "countries.pk": "Pakistan",
   "countries.pw": "Palau",
   "countries.ps": "Palestinian Territory, Occupied",
   "countries.pa": "Panama",
   "countries.pg": "Papua New Guinea",
   "countries.py": "Paraguay",
   "countries.pe": "Peru",
   "countries.ph": "Philippines",
   "countries.pn": "Pitcairn",
   "countries.pl": "Poland",
   "countries.pt": "Portugal",
   "countries.pr": "Puerto Rico",
   "countries.qa": "Qatar",
   "countries.ro": "Romania",
   "countries.ru": "Russian Federation",
   "countries.rw": "Rwanda",
   "countries.re": "Réunion",
   "countries.bl": "Saint Barthélemy",
   "countries.sh": "Saint Helena, Ascension and Tristan da Cunha",
   "countries.kn": "Saint Kitts and Nevis",
   "countries.lc": "Saint Lucia",
   "countries.mf": "Saint Martin (French part)",
   "countries.pm": "Saint Pierre and Miquelon",
   "countries.vc": "Saint Vincent and the Grenadines",
   "countries.ws": "Samoa",
   "countries.sm": "San Marino",
   "countries.st": "Sao Tome and Principe",
   "countries.sa": "Saudi Arabia",
   "countries.sn": "Senegal",
   "countries.rs": "Serbia",
   "countries.sc": "Seychelles",
   "countries.sl": "Sierra Leone",
   "countries.sg": "Singapore",
   "countries.sx": "Sint Maarten (Dutch part)",
   "countries.sk": "Slovakia",
   "countries.si": "Slovenia",
   "countries.sb": "Solomon Islands",
   "countries.so": "Somalia",
   "countries.za": "South Africa",
   "countries.gs": "South Georgia and the South Sandwich Islands",
   "countries.ss": "South Sudan",
   "countries.es": "Spain",
   "countries.lk": "Sri Lanka",
   "countries.sd": "Sudan",
   "countries.sr": "Suriname",
   "countries.sj": "Svalbard and Jan Mayen",
   "countries.sz": "Swaziland",
   "countries.se": "Sweden",
   "countries.ch": "Switzerland",
   "countries.sy": "Syrian Arab Republic",
   "countries.tw": "Taiwan",
   "countries.tj": "Tajikistan",
   "countries.tz": "Tanzania, United Republic of",
   "countries.th": "Thailand",
   "countries.tl": "Timor-Leste",
   "countries.tg": "Togo",
   "countries.tk": "Tokelau",
   "countries.to": "Tonga",
   "countries.tt": "Trinidad and Tobago",
   "countries.tn": "Tunisia",
   "countries.tr": "Turkey",
   "countries.tm": "Turkmenistan",
   "countries.tc": "Turks and Caicos Islands",
   "countries.tv": "Tuvalu",
   "countries.ug": "Uganda",
   "countries.ua": "Ukraine",
   "countries.ae": "United Arab Emirates",
   "countries.gb": "United Kingdom",
   "countries.us": "United States",
   "countries.um": "United States Minor Outlying Islands",
   "countries.uy": "Uruguay",
   "countries.uz": "Uzbekistan",
   "countries.vu": "Vanuatu",
   "countries.ve": "Venezuela, Bolivarian Republic of",
   "countries.vn": "Viet Nam",
   "countries.vg": "Virgin Islands, British",
   "countries.vi": "Virgin Islands, U.S.",
   "countries.wf": "Wallis and Futuna",
   "countries.eh": "Western Sahara",
   "countries.ye": "Yemen",
   "countries.zm": "Zambia",
   "countries.zw": "Zimbabwe",
   "countries.ax": "Ålandds",

   "check_info_popup": "Check Info Popup",
   "confirm": "Confirm",
   "setup_account_title": "Setup Your Account",
   "return_odin": "Return to Odin",
   "affiliate_view": "Affiliate View",
   "register_success_message": "We have sent an invitation instruction to your email. Please check your email {email} to complete your registration, it might take a few minutes to show up in your inbox.(Also check spam incase)",
   "register_failed_message": "Your email already existing in used.",

   "fs_popup_error.session-payload-invalid": "Could not understand the data in the 'session' parameter.",
   "fs_popup_error.session-expired": "Serialized session data has expired.",
   "fs_popup_error.empty-session": "Tried to finalize / checkout a session with no items.",
   "fs_popup_error.envelope-invalid": "Error parsing secure payload",
   "fs_popup_error.quantity-invalid": "Passed an invalid quantity value.",
   "fs_popup_error.not-removable": "The product is not allowed to be removed from the session.",
   "fs_popup_error.path-not-found": "Could not find the product variation.",
   "fs_popup_error.variation-not-found": "Could not find the variation of product",

   "my_sessions.view-hand": "View Hands",
   "battle_reports": "Battle Reports"
}
