import { TONES, FULL_CARDS, CARDS_LIST } from 'config/constants'
import { sample, range, isEmpty, filter, includes } from 'lodash'
import { randomTone,  getToneFromCardDup } from 'services/customOneOptionFlopCardsService'

export const getCardsDisconnected = (data, count) => {
  const { highest, medium, lowest } = data
  const indexHighest = CARDS_LIST.findIndex(i => i === highest)
  let indexMedium = CARDS_LIST.findIndex(i => i === medium)
  let indexLowest = CARDS_LIST.findIndex(i => i === lowest)
  if (count === 1) {
    if (indexMedium === -1 && indexLowest === -1 && indexHighest < 7) {
      const mediumIndex = sample(range(indexHighest + 3, CARDS_LIST.length - 3, 1))
      const lowestIndex = sample(range(mediumIndex + 3, CARDS_LIST.length, 1))
      return [highest, CARDS_LIST[mediumIndex], CARDS_LIST[lowestIndex]]
    }
    if (indexHighest === -1 && indexLowest === -1 && indexMedium > 2 && indexMedium < 10) {
      const highestIndex = sample(range(0, indexMedium - 2, 1))
      const lowestIndex = sample(range(indexMedium + 3, CARDS_LIST.length, 1))
      return [CARDS_LIST[highestIndex], medium, CARDS_LIST[lowestIndex]]
    }
    if(indexHighest === -1 && indexMedium === -1 && indexLowest > 5) {
      const mediumIndex = sample(range(3, indexLowest - 2, 1))
      const highestIndex = sample(range(0, mediumIndex - 2, 1))
      return [CARDS_LIST[highestIndex], CARDS_LIST[mediumIndex], lowest]
    }
  } else {
    if (indexHighest === -1 && indexMedium > 2 && indexMedium < 10) {
      const highestIndex = sample(range(0, indexMedium - 2, 1))
      if (indexLowest <= indexMedium + 2) {
        indexLowest = sample(range(indexMedium + 3, CARDS_LIST.length, 1))
      }
      return [CARDS_LIST[highestIndex], medium, CARDS_LIST[indexLowest]]
    }
    if (indexHighest > -1 && indexHighest < 7) {
      if (indexMedium <= indexHighest + 2 || indexMedium > 9) {
        const maxIndex = indexLowest === -1 || indexLowest < indexHighest + 5 ? 10 : indexLowest - 2
        indexMedium = sample(range(indexHighest + 3, maxIndex, 1))
      }
      if (indexLowest < indexMedium + 2 ) {
        indexLowest = sample(range(indexMedium + 3, CARDS_LIST.length, 1))
      }
      return [highest, CARDS_LIST[indexMedium], CARDS_LIST[indexLowest]]
    }
  }
  return []
}

export const renderConnectedCards = (item=null) => {
  const cards = []
  const allCards = [...FULL_CARDS]
  let card = isEmpty(item) ? sample(allCards) : item
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  console.log('connected')
  range(0,3,1).forEach((item) => {
    if (item > 0) {
      if (idx === 2 && allCards.length === 3) {
        number = item === 1 ? 1 : 0
      } else {
        switch (idx) {
          case 0:
            number = item === 1 ? 1 : 2
            break
          case 1:
            number = item === 1 ? 0 : 2
            break
          case 11:
            number = item === 1 ? 10 : 12
            break
          case 12:
            number = item === 1 ? 11 : 10
            break
          default:
            if (item === 1) {
              number = sample([idx - 1, idx + 1])
            } else {
              const index = number > idx ? number : idx
              number = index + 1
            }
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}`)
    cards.push(`${card}`)
  })
  return cards
}

export const generateFlopCards = (suit, cards) => {
  switch (suit) {
    case 'rainbow':
      return renderRainbowCards(cards)
    case 'flush_draw':
      return renderFlushDrawCards(cards)
    default:
      return renderMonotoneCards(cards)
  }
}

const renderRainbowCards = (allCards) => {
  const tonesClone = [...TONES]
  console.log(`rain bow`)
  let cards = []
  allCards.forEach(card => {
    const tone = randomTone(tonesClone)
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

const renderFlushDrawCards = (allCards) => {
  const tonesClone = [...TONES]
  const tonesExists = []
  const cards = []
  console.log('flush draw')
  const itemDup = filter(allCards, (val, i, iteratee) => includes(iteratee, val, i + 1))[0] || ''
  allCards.forEach((card, index) => {
    let tone = sample(tonesClone)
    if (index === 2) {
      tone = getToneFromCardDup(cards, itemDup)
    } else {
      tone = randomTone(tonesClone)
      tonesExists.push(tone)
    }
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

const renderMonotoneCards = (allCards) => {
  const tone = sample(TONES)
  const cards = []
  console.log('monotone')
  allCards.forEach((card) => {
    console.log(`${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const suitRandom = () => sample(['rainbow', 'flush_draw', 'monotone'])
export const pairedRandom = () => sample(['not_paired', 'paired', 'trips'])
export const connectednessRandom = () => sample(['disconnected', 'medium', 'connected'])
