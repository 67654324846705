import { TONES, FULL_CARDS, CARDS_LIST } from 'config/constants'
import { sample, range, isEmpty, filter, includes, cloneDeep } from 'lodash'

export const renderJustDisconnectedCards = (item=null) => {
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = isEmpty(item) ? sample(allCards) : item
  let idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      if (idx < 9 && idx > 3 ) {
        if (item === 1) {
          number = sample(range(0, idx - 3, 1))
        } else {
          number = sample(range(idx + 4, 13, 1))
        }
      } else if (idx < 4) {
        if (item === 1) {
          number = sample(range(idx + 4, 9, 1))
        } else {
          number = sample(range(number + 4, 13, 1))
        }
      } else if (idx > 8) {
        if (item === 1) {
          number = sample(range(5, idx - 3, 1))
        } else {
          number = sample(range(0, number - 3, 1))
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustMediumCards = (item=null) => {
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = isEmpty(item) ? sample(allCards) : item
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      if (idx === 0) {
        number = item === 1 ? 1 : sample(range(3, 5, 1))
      } else if (idx > 0 && idx < 3) {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          number = sample(range(index + 2, index + 4, 1))
        }
      } else if (idx > 9 && idx < 12) {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? idx : number
          number = sample(range(index - 3, index - 1, 1))
        }
      } else if (idx === 12) {
        number = item === 1 ? 11 : sample(range(8, 10, 1))
      } else {
        if(item === 1) {
          number = sample([idx - 1, idx + 1])
        } else {
          const index = number > idx ? number : idx
          number = sample([index + 2, index - 3])
        }
      }
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const renderJustConnectedCards = () => {
  const allCards = [...FULL_CARDS]
  const cards = []
  let card = sample(allCards)
  const idx = allCards.findIndex(i => i === card)
  let number = 0
  range(0,3,1).forEach((item) => {
    const tone = sample(TONES)
    if (item > 0) {
      number = positionCard(item, idx, number)
      card = allCards[number]
    }
    console.log(`${idx}-${item} - ${card}${tone}`)
    cards.push(`${card}${tone}`)
  })
  return cards
}

export const randomTone = (tonesClone) => {
  const tone = sample(tonesClone)
  tonesClone.splice(tonesClone.findIndex(i => i === tone), 1)
  return tone
}

export const randomCard = (allCards) => {
  const card = sample(allCards)
  allCards.splice(allCards.findIndex(i => i === card), 1)
  return card
}

export const getToneFromCardDup = (cards, itemDup) => {
  const tonesExists = []
  const item = filter(cards, (val, i) => {
    tonesExists.push(val.slice(-1))
    return val.slice(0, -1) !== itemDup
  })[0]
  return isEmpty(item) ? sample(tonesExists) : item.slice(-1)
}

const positionCard = (item, idx, num) => {
  switch (idx) {
    case 0:
      return item === 1 ? 1 : 2
    case 1:
      return item === 1 ? 0 : 2
    case 11:
      return item === 1 ? 10 : 12
    case 12:
      return item === 1 ? 11 : 10
    default:
      if (item === 1) {
        return sample([idx - 1, idx + 1])
      } else {
        const index = num > idx ? num : idx
        return index + 1
      }
  }
}

export const checkConflictOptions = (options, obj) => {
  const { connectedness } = options
  const { highest, medium, lowest } = obj
  let shouldRefesh = false
  if (connectedness === 'disconnected') return shouldRefesh

  const indexHighest = CARDS_LIST.findIndex(i => i === highest)
  const indexMedium = CARDS_LIST.findIndex(i => i === medium)
  const indexLowest = CARDS_LIST.findIndex(i => i === lowest)
  const maxNumber = connectedness === 'connected' ? 1 : 3

  if (indexHighest === -1) { shouldRefesh = Math.abs(indexMedium - indexLowest) > maxNumber }
  if (indexLowest === -1) { shouldRefesh = Math.abs(indexHighest - indexMedium) > maxNumber }
  if (indexMedium === -1) { shouldRefesh = Math.abs(indexHighest - indexLowest) > (maxNumber + 1) }

  return shouldRefesh
}

export const generateCardsWithConflict = (connectedness) => {
  switch (connectedness) {
    case 'disconnected':
      return renderJustDisconnectedCards()
    case 'medium':
      return renderJustMediumCards()
    default:
      return renderJustConnectedCards()
  }
}

export const randomCardsFromOptions = (data) => {
  const { highest, medium, lowest } = data
  const highestRandom = isEmpty(highest) ? '' : sample(highest)
  const mediumRandom = isEmpty(medium) ? '' : sample(medium)
  const lowestRandom = isEmpty(lowest) ? '' : sample(lowest)
  let objCards = {
    highest: highestRandom,
    medium: mediumRandom,
    lowest: lowestRandom
  }
  console.log(`random: ${highestRandom}, ${mediumRandom}, ${lowestRandom}`)
  return objCards
}

export const countingAndRefeshObjCards = (options, objCards, data) => {
  const { suit } = options
  let initCards = objCards
  if (suit !== 'monotone') { initCards = checkResetObjCards(initCards, data) }
  let count = Object.values(initCards).filter(i => !isEmpty(i)).length
  const itemDup = filter(Object.values(initCards), (val, i, iteratee) => includes(iteratee, val, i + 1))[0] || ''
  if (count === 0) {
    initCards['highest'] = sample(CARDS_LIST.slice(0, 11))
    count = 1
  }
  return { count, initCards, itemDup }
}

const checkResetObjCards = (obj, data) => {
  const { highest, medium, lowest } = data
  const initCards = cloneDeep(obj)

  if (!isEmpty(initCards['highest'])) {
    const highestClone = cloneDeep(highest)
    const idx = CARDS_LIST.findIndex(i => i === initCards['highest'])
    if (idx > 10) {
      const index = highestClone.findIndex(i => i === initCards['highest'])
      highestClone.splice(index, 1)
      const rdom = sample(highestClone)
      const value = isEmpty(rdom) ? '' : rdom
      initCards['highest'] = value
    }
  }
  if (!isEmpty(initCards['medium'])) {
    let mediumClone = cloneDeep(medium)
    const idx = CARDS_LIST.findIndex(i => i === initCards['medium'])
    if (idx === 0 || idx === 12) {
      const index = mediumClone.findIndex(i => i === initCards['medium'])
      mediumClone.splice(index, 1)
      const rdom = sample(mediumClone)
      const value = isEmpty(rdom) ? '' : rdom
      initCards['medium'] = value
    }
  }
  if (!isEmpty(initCards['lowest'])) {
    let lowestClone = cloneDeep(lowest)
    const idx = CARDS_LIST.findIndex(i => i === initCards['lowest'])
    if (idx < 2) {
      const index = lowestClone.findIndex(i => i === initCards['lowest'])
      lowestClone.splice(index, 1)
      const rdom = sample(lowestClone)
      const value = isEmpty(rdom) ? '' : rdom
      initCards['lowest'] = value
    }
  }
  return initCards
}



