import { renderConnectedCards, generateFlopCards, getCardsDisconnected } from 'services/randomFlopCards'
import {
  renderJustDisconnectedCards,
  renderJustMediumCards,
  checkConflictOptions,
  generateCardsWithConflict,
  randomCardsFromOptions,
  countingAndRefeshObjCards,
} from 'services/customOneOptionFlopCardsService'

import { CARDS_LIST } from 'config/constants'
import { isEmpty, isArray, range, xor, filter, union, sample, min, max } from 'lodash'

const generateCards = (options, data, count) => {
  const { connectedness } = options
  let result = []
  switch (connectedness) {
    case 'connected':
      result = getCardsConnectedMedium(data, count, connectedness)
      if (isEmpty(result)) { result = renderConnectedCards() }
      break
    case 'medium':
      result = getCardsConnectedMedium(data, count, connectedness)
      if (isEmpty(result)) {
        result = renderJustMediumCards()
        result = result.map(i => i.slice(0, -1))
      }
      break
    default:
      result = getCardsDisconnected(data, count)
      if (isEmpty(result)) {
        result = renderJustDisconnectedCards()
        result = result.map(i => i.slice(0, -1))
      }
  }
  return result
}

const generateCardNoneConnectedness = (options, obj) => {
  const { paired } = options
  const { highest } = obj
  if ( paired === 'paired') {
    let cardSampleFirst = sample(xor(CARDS_LIST, [highest]))
      const indexHighest = CARDS_LIST.findIndex(i => i === highest)
      if (indexHighest > -1 && indexHighest < CARDS_LIST.length - 1) {
        cardSampleFirst = CARDS_LIST[sample(range(indexHighest + 1, CARDS_LIST.length, 1))]
      }
    let cardSampleLastPaired = sample([highest, cardSampleFirst])
    return [highest, cardSampleFirst, cardSampleLastPaired]
  } else {
    return generateCards(options, obj, 1)
  }
}

const getCardsConnectedMedium = (data, count, connectedness) => {
  const { highest, medium, lowest } = data
  const indexHighest = CARDS_LIST.findIndex(i => i === highest)
  const indexMedium = CARDS_LIST.findIndex(i => i === medium)
  const indexLowest = CARDS_LIST.findIndex(i => i === lowest)
  const maxHighest = connectedness === 'connected' ? 11 : 10
  const maxMedium = connectedness === 'connected' ? 10 : 11
  const numLast = connectedness === 'connected' ? 2 : 3
  let cards = []
  if (count === 1) {
    if (indexMedium === -1 && indexLowest === -1) {
      cards = indexHighest < maxHighest ? [highest, CARDS_LIST[indexHighest + 1], CARDS_LIST[indexHighest + numLast]] : []
    }
    if (indexHighest === -1 && indexLowest === -1) {
      cards = indexMedium > 0 && indexMedium < maxHighest + 1 ? [CARDS_LIST[indexMedium - 1], medium, CARDS_LIST[indexMedium + numLast - 1]] : []
    }
    if(indexHighest === -1 && indexMedium === -1) {
      cards = indexLowest > 1 ? [CARDS_LIST[indexLowest - numLast], CARDS_LIST[indexLowest - numLast + 1], lowest] : []
    }
  } else {
    if (indexHighest === -1) {
      const inValid = indexMedium === 0 || indexMedium > maxMedium
      cards = inValid ? [] : [CARDS_LIST[indexMedium - 1], medium, CARDS_LIST[indexMedium + numLast - 1]]
    } else {
      cards = indexHighest > maxHighest - 1 ? [] : [highest, CARDS_LIST[indexHighest + 1], CARDS_LIST[indexHighest + numLast]]
    }
  }
  return cards
}

export const getFlopCards = (data, options) => {
  const { suit } = options
  let objCards = randomCardsFromOptions(data)

  const result = countingAndRefeshObjCards(options, objCards, data)
  const count = result['count']
  const itemDup = result['itemDup']
  objCards = result['initCards']

  switch (suit) {
    case 'monotone':
      if (count < 3) {
        const cards = generateCards(options, objCards, count)
        objCards = { highest: cards[0], medium: cards[1], lowest: cards[2] }
      } else {
        objCards = generateObjectCardsMonotone(options, objCards, data, itemDup, count)
      }
      break
    case 'flush_draw':
      objCards = randomFlopCardsFlushDraw(objCards, options, data, itemDup, count)
      break
    default:
      objCards = renderCardsRainbow(options, objCards, data, itemDup, count)
  }
  console.log(`highest: ${objCards['highest']}, medium: ${objCards['medium']}, lowest: ${objCards['lowest']}`)
  const cards = [objCards['highest'], objCards['medium'], objCards['lowest']]
  return generateFlopCards(suit, cards)
}

const generateObjectCardsMonotone = (options, obj, data, itemDup, count) => {
  let initCards = obj
  if (!isEmpty(itemDup)) {
    initCards = { highest: sample(CARDS_LIST.slice(0, 11)), medium: '', lowest: '' }
    const cards = generateCards(options, initCards, 1)
    initCards = { highest: cards[0], medium: cards[1], lowest: cards[2] }
    console.log(`itemdup monotone: ${cards[0]}, ${cards[1]}, ${cards[2]}`)
  } else {
    const listClone = [...CARDS_LIST]
    if (isEmpty(initCards['highest'])) {
      const lstIndex = getIndexHighest(initCards, data)
      const card = sample(listClone.slice(0, lstIndex - 1))
      initCards['highest'] = card
    }
    if (isEmpty(initCards['medium'])) {
      let lst = getListIndexMedium(initCards, data)
      const lowestCard = isEmpty(initCards['lowest']) ? '2' : initCards['lowest']
      const cardsExists = filter([initCards['highest'], lowestCard], (i) => !isEmpty(i))
      lst = union(lst, cardsExists)
      const card = sample(xor(listClone, lst))
      initCards['medium'] = card
    }
    if (isEmpty(initCards['lowest'])) {
      const lstIndex = getIndexLowest(initCards, data)
      const card = sample(listClone.slice(lstIndex + 1, CARDS_LIST.length))
      initCards['lowest'] = card
    }
  }
  return initCards
}

const getIndexHighest = (obj, data={}) => {
  const { medium, lowest } = obj
  const m = isArray(medium) ? medium : [medium]
  const l = isArray(lowest) ? lowest : [lowest]
  let valuesMedium = indexOfCards(m)
  let valuesLowest = indexOfCards(l)
  if (!isEmpty(data)) {
    valuesMedium = data['medium'].length < 2 ? valuesMedium : indexOfCards(data['medium'])
    valuesLowest = data['lowest'].length < 2 ? valuesLowest : indexOfCards(data['lowest'])
  }
  let index = -1
  if ( !isEmpty(medium) && !isEmpty(lowest) ) {
    index = valuesMedium.minNumber === valuesLowest.minNumber ? valuesMedium.minNumber + 1 : min([valuesMedium.minNumber + 1, valuesLowest.minNumber])
  } else {
    if (!isEmpty(medium)) {
      index = valuesMedium.minNumber === 0 ? valuesMedium.maxNumber : valuesMedium.minNumber + 1
    }
    if (!isEmpty(lowest)) {
      index = valuesLowest.minNumber === 0 ? valuesLowest.maxNumber : valuesLowest.minNumber
    }
  }
  return index
}

const getListIndexMedium = (obj, data={}) => {
  const { highest, lowest } = obj
  const h = isArray(highest) ? highest : [highest]
  const l = isArray(lowest) ? lowest : [lowest]
  let valuesHighest = indexOfCards(h)
  let valuesLowest = indexOfCards(l)
  if (!isEmpty(data)) {
    valuesHighest = data['highest'].length < 2 ? valuesHighest : indexOfCards(data['highest'])
    valuesLowest = data['lowest'].length < 2 ? valuesLowest : indexOfCards(data['lowest'])
  }
  let lst = []
  if(!isEmpty(highest)) {
    const indexCard = valuesHighest.maxNumber
    lst = CARDS_LIST.slice(0, indexCard)
  }

  if (!isEmpty(lowest)) {
    const indexCard = valuesLowest.minNumber === 0 ? valuesLowest.maxNumber : valuesLowest.minNumber
    lst = lst.concat(CARDS_LIST.slice(indexCard + 1, CARDS_LIST.length))
  }
  return lst
}

const getIndexLowest = (obj, data={}) => {
  const { highest, medium } = obj
  const h = isArray(highest) ? highest : [highest]
  const m = isArray(medium) ? medium : [medium]
  let valuesHighest = indexOfCards(h)
  let valuesMedium = indexOfCards(m)
  if (!isEmpty(data)) {
    valuesHighest = data['highest'].length < 2 ? valuesHighest : indexOfCards(data['highest'])
    valuesMedium = data['medium'].length < 2 ? valuesMedium : indexOfCards(data['medium'])
  }
  let index = -1
  if (!isEmpty(highest)) {
    index = valuesHighest.maxNumber + 1
  }
  if (!isEmpty(medium)) {
    const indexCard = valuesMedium.maxNumber
    index = indexCard
  }
  return index
}

const indexOfCards = (values) => {
  if (isEmpty(values))
    return { maxNumber: 0, minNumber: 0 }
  let highestNumbers = []
  values.forEach( item => {
    highestNumbers.push(CARDS_LIST.findIndex(i => i === item))
  })
  return { maxNumber: max(highestNumbers), minNumber: min(highestNumbers) }
}

export const lstHighestCardDisabled = (strategySelection) => {
  let lstIndex = getIndexHighest(strategySelection)
  return lstIndex === -1 ? [] : CARDS_LIST.slice(lstIndex, CARDS_LIST.length)
}

export const lstMediumCardDisabled = (strategySelection) => {
  return getListIndexMedium(strategySelection)
}

export const lstLowestCardDisabled = (strategySelection) => {
  const lstIndex = getIndexLowest(strategySelection)
  return lstIndex === -1 ? [] : CARDS_LIST.slice(0, lstIndex)
}

export const renderPairedOnlyFlushDraw = (obj) => {
  const { highest, medium, lowest } = obj
  let cardsValue = filter([highest, medium, lowest], (i) => !isEmpty(i))
  let cards = cardsValue
  const cardSampleFirst = sample(xor(CARDS_LIST, cardsValue))
  let cardSampleLastPaired = cardsValue.length === 1 ? sample([cardsValue[0], cardSampleFirst]) : sample(cardsValue)
  if (cardsValue[0] === cardsValue[1]) {
    cardSampleLastPaired = sample(xor(CARDS_LIST, cardsValue))
  }
  cards = cardsValue.length === 1 ? [cardsValue[0], cardSampleFirst, cardSampleLastPaired] : cardsValue.concat(cardSampleLastPaired)
  return cards
}

export const randomFlopCardsFlushDraw = (obj, options, data, itemDup, count) => {
  let initCards = obj
  let cards = []
  if (isEmpty(itemDup)) {
    if (count === 3) return initCards
    cards = options.paired === 'not_paired' && count === 1 ? generateCards(options, initCards, 1) : renderPairedOnlyFlushDraw(initCards)
    console.log(`flush_draw none itemdup: ${cards[0]}, ${cards[1]}, ${cards[2]}`)
    initCards = { highest: cards[0], medium: cards[1], lowest: cards[2] }
  } else {
    const objCardsDup = []
    Object.keys(initCards).forEach(i => { if (initCards[i] === itemDup) objCardsDup.push(i) })
    if (objCardsDup.length === 3) {
      const randomValue = sample(CARDS_LIST.slice(0, 13))
      initCards = { highest: randomValue, medium: '', lowest: '' }
      cards = generateCardNoneConnectedness(options, initCards, 1)
      console.log(`itemdup flush_draw: ${cards[0]}, ${cards[1]}, ${cards[2]}`)
      initCards = { highest: cards[0], medium: cards[1], lowest: cards[2] }
    } else {
      if (count === 3) return initCards
      const cardNameMissed = xor(Object.keys(initCards), objCardsDup)[0]
      initCards[cardNameMissed] = sample(xor(CARDS_LIST, objCardsDup))
    }
  }
  return initCards
}

export const renderCardsRainbow = (options, obj, data, itemDup, count) => {
  const { paired, connectedness } = options
  let cardsFilter = filter(Object.values(obj), (i) => !isEmpty(i))
  let cards = []
  switch (count) {
    case 1:
      cards = paired === 'not_paired' ? generateCards(options, obj, 1) : renderCardsPaired(paired, obj, cardsFilter)
      break
    case 2:
      if (isEmpty(itemDup) && paired === 'trips') {
        const cardsSample = sample(CARDS_LIST)
        cards = [cardsSample, cardsSample, cardsSample]
      } else {
        if (paired !== 'not_paired') {
          cards = renderCardsPaired(paired, obj, cardsFilter)
          break
        }

        if (checkConflictOptions(options, obj)) {
          cards = generateCardsWithConflict(connectedness)
          cards = cards.map(i => i.slice(0, -1))
        } else {
          cards = generateCards(options, obj, count)
        }
      }
      break
    default:
      cards = Object.values(obj)
  }
  return { highest: cards[0], medium: cards[1], lowest: cards[2] }
}

export const renderCardsPaired = (paired, obj, cardsValue=[]) => {
  switch (paired) {
    case 'paired':
      return renderPairedOnlyFlushDraw(obj)
    case 'trips':
      return [cardsValue[0], cardsValue[0], cardsValue[0]]
    default:
      return cardsValue
  }
}


