import React, { Component, Fragment } from 'react'
import {
  // A <Router> that uses the HTML5 history API (pushState, replaceState and the popstate event) to keep your UI in sync with the URL.
  BrowserRouter,
  Route,
  // Switch Renders the first child <Route> or <Redirect> that matches the location.
  Switch,
  Redirect
} from 'react-router-dom'
import loadable from '@loadable/component'
import Layout from '../components/Layout/component'
import { connect } from 'react-redux'
import { requireLoggedOut } from '../services/auth_service'
import { getAccess, giveSpecialAccess } from '../services/basic_auth_service'
import { fetchRoles } from 'actions/role.actions'
import { LINKS } from 'config/constants'
import axios from 'axios'
import privateRoutes from './routes/private'
import { isEmpty, toLower, map } from 'lodash'
import Loading from '../components/Custom/Loading.jsx'

import SignIn from '../components/SignIn/component'
import ForgotPassword from '../components/ForgotPassword/component'
import ResetPassword from '../components/ResetPassword/component'
import SignUp from '../components/SignUp/component'
import { urlFromAffiliate } from "utils/utils"

const LandingPage = loadable(() => import('../components/LandingPage/component'), {
 fallback: <Loading />,
})
const ContactUs = loadable(() => import('../components/ContactUs/component'))
const PrivacyPolicy = loadable(() => import('../components/PrivacyPolicy/component'))
const TermsConditions = loadable(() => import('../components/TermsConditions/component'))
const BasicAuth = loadable(() => import('../components/BasicAuth/BasicAuth'))
const ThankYouPage = loadable(() => import('components/ThankYouPage/component'))
const SetupAccountPage = loadable(() => import('components/MyAccount/SetupAccountPage/SetupAccountPage'))
const Tutorial = loadable(() => import('components/Tutorial/component'))
const Benchmarks = loadable(() => import('components/Benchmarks'))

const generateRequireSignInWrapper = ({redirectPathIfNotSignedIn = ''}) => {
  return (WrappedComponent) => {
    class CRequireSignedIn extends React.Component {

      removeKey = (e) =>  {
        e.preventDefault();
        const payload = {
          offline: false
        }
        const user = this.props.currentUser.attributes
        if (!isEmpty(user) && localStorage.getItem('first-sign-in')){
          window.axios.put(`/users/${user.id}/update_info_current_login`, payload)
        }
        localStorage.removeItem('first-sign-in')
      }

      roles() {
        axios.get('/roles').then(({ data }) => {
          this.props.fetchRoles(data.roles)
        })
      }

      render() {
        const { currentUser, history } = this.props;
        const isSignedIn = currentUser.isSignedIn || false;
        const emailConfirmed = currentUser.attributes.emailConfirmed || false;
        const roles = this.props.roles;
        if (isSignedIn && emailConfirmed) {
          if (isEmpty(roles)) {
            if (history.location.pathname === '/main-page' && history.location.state && history.location.state.mysims) {
              history.replace('/main-page', { accept: true })
            }
            this.roles()
          }
          return <WrappedComponent {...this.props} />;
        } else {
          return <Redirect to={redirectPathIfNotSignedIn}/>;
        }
      }
    }
    const mapStateToProps = (state) => ({
      currentUser: state.reduxTokenAuth.currentUser,
      roles: state.roles,
    })

    const mapDispatchToProps = {
      fetchRoles: fetchRoles
    }

    const RequireSignedIn = connect(
      mapStateToProps,
      mapDispatchToProps
    )(CRequireSignedIn)
    return RequireSignedIn;
  }
}

const requireSignIn = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/signin',
})

class BasicAuthWrapper extends Component {
  render() {
    const { pathname } = this.props.location
    let userData = JSON.parse(localStorage.getItem('basicAuthData') || '{}')
    let authenticated = false
    if (process.env.REACT_APP_BASIC_AUTH_ENVIRONMENT === 'production'){
      authenticated = true
    } else {
      authenticated = process.env.REACT_APP_BASIC_AUTH_ENVIRONMENT === 'staging' && ['/pokercode'].includes(toLower(pathname)) ?
                        giveSpecialAccess() : getAccess(userData)
    }
    if (authenticated) {
      return <Fragment>{this.props.children}</Fragment>
    } else {
      return <Redirect to='/get-access'/>;
    }
  }
}

const privatePages = map(privateRoutes, (route, key) => {
  const { component, path } = route;
  return <Route exact path={path} key={key} component={requireSignIn(component)}/>
 })

class Routes extends Component {
  render() {
    const { isSignedIn, attributes: { emailConfirmed } } = this.props.currentUser
    const userSignedIn = isSignedIn && emailConfirmed && !isEmpty(localStorage.getItem('access-token'))
    const  pathname = window.location.pathname
    const links = urlFromAffiliate(this.props.affiliateDiscountCodes);

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/get-access"
            component={BasicAuth}/>
          <BasicAuthWrapper>
            <Layout>
              <Route exact path={links}
                    component={LandingPage}/>
              <Route exact path="/signin"
                    component={requireLoggedOut(SignIn, userSignedIn)}/>
              <Route exact path="/signup"
                    component={requireLoggedOut(SignUp, userSignedIn)}/>
              <Route exact path="/forgot-password"
                    component={requireLoggedOut(ForgotPassword, userSignedIn)}/>
              <Route exact path="/reset-password"
                    component={requireLoggedOut(ResetPassword, userSignedIn)}/>
              <Route exact path="/contact-us"
                    component={ContactUs}/>
              <Route exact path="/terms-of-service"
                    component={TermsConditions}/>
              <Route exact path="/privacy-policy"
                    component={PrivacyPolicy}/>
              <Route exact path="/tutorial"
                    component={Tutorial}/>
              <Route exact path="/thank-you"
                    component={ThankYouPage}/>
              <Route exact path="/setup-account"
                    component={SetupAccountPage}/>
              <Route exact path="/benchmarks"
                    component={Benchmarks}/>
              {
                privatePages.map(page => {
                  return page
                })
              }
              { pathname && pathname === '/wsop' &&
                <Redirect to='/'/>
              }
            </Layout>
          </BasicAuthWrapper>
        </Switch>
      </BrowserRouter>
    )
  }
}

const mapStoreToProps = (store) => ({
  currentUser: store.reduxTokenAuth.currentUser,
  affiliateDiscountCodes: store.affiliateDiscountCodes,
})

export default connect(
  mapStoreToProps,
  null
)(Routes)
