import { mySessionsActions } from '../constants/index';

const initialState = { mySessions: [], countPages: 1 };

export function battleSession(state = initialState, action) {
  switch (action.type) {
    case mySessionsActions.UPDATE_MY_SESSIONS_DATA:
      return {
        ...state,
        mySessions: action.payload.sessions,
        countPages: action.payload.countPages
      }
    default:
      return state;
  }
}