const initialState = {};

export function setting(state = initialState, action) {
  switch (action.type) {
    case 'ENABLE_SETTING':
      return action.payload
    case 'DISABLED_SETTING':
      return action.payload
    case 'ENABLE_MENU':
      return action.payload
    case 'DISABLED_MENU':
      return action.payload
    default:
      return state;
  }
}
