export const enableSetting = () => {
  return {
    type: 'ENABLE_SETTING',
    payload: { options: true }
  }
}

export const disabledSetting = () => {
  return {
    type: 'DISABLED_SETTING',
    payload: { options: false }
  }
}

export const enableMenu = (bot) => {
  return {
    type: 'ENABLE_MENU',
    payload: { menu: true }
  }
}

export const disabledMenu = (bot) => {
  return {
    type: 'DISABLED_MENU',
    payload: { menu: false }
  }
}
